import { Box, Button, Card, CardContent, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CompanyCardProps {
  name: string;
  phone: string;
  balance: number;
  showReplenishButton?: boolean;
  onReplenish?: VoidFunction;
}

const CompanyCard = ({
  name,
  phone,
  balance,
  showReplenishButton,
  onReplenish
}: CompanyCardProps) => {
  const { t } = useTranslation();

  const formattedPhone =
    phone.length === 11
      ? phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5")
      : phone;

  const formattedBalance = new Intl.NumberFormat("ru-KZ", {
    style: "currency",
    currency: "KZT",
    currencyDisplay: "symbol"
  }).format(balance / 100);

  return (
    <Card data-testid='company-card'>
      <CardContent>
        <Typography
          component='h3'
          variant='h6'
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {name}
        </Typography>

        <Divider sx={{ mt: 1 }} />

        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 1,
            gridTemplateColumns: {
              sx: "1fr",
              sm: "auto 1fr"
            },
            mt: 1
          }}
        >
          <Typography sx={{ color: "text.secondary" }}>
            {t("pages.LegalEntityBalance.list.labels.phone")}:
          </Typography>

          {formattedPhone || "111111111111111111111111111111" || "-"}

          <Typography sx={{ color: "text.secondary" }}>
            {t("pages.LegalEntityBalance.list.labels.balance")}:
          </Typography>

          {formattedBalance}
        </Box>

        {showReplenishButton && (
          <>
            <Divider sx={{ mt: 1 }} />

            <Button variant='contained' onClick={onReplenish} fullWidth sx={{ mt: 1 }}>
              {t("pages.LegalEntityBalance.replenish")}
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyCard;
