/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Abonement {
  /** uuid сущности */
  uuid?: Uuid;
  name?: Record<string, string>;
  active?: boolean;
  /**
   * количество активных назначений
   * @format int32
   */
  currentUsesCount?: number;
  zones?: string[];
  duration?: Duration;
  startType?: StartType;
  /**
   * цена в руб. только целое
   * @format int64
   */
  price?: string;
  isDeleted?: boolean;
}

export interface AbonementCreateRequest {
  name?: Record<string, string>;
  zones?: string[];
  duration?: Duration;
  startType?: StartType;
  /**
   * цена в руб. только целое
   * @format int64
   */
  price?: string;
}

export interface AbonementGrant {
  /** uuid сущности */
  uuid?: Uuid;
  /**
   * дата начала действия 1664571600000
   * @format int64
   */
  start?: string;
  /**
   * дата окончания действия 1664571600000
   * @format int64
   */
  end?: string;
  abonementUuid?: Uuid;
  /**
   * дата создания 1664571600000
   * @format int64
   */
  createdAt?: string;
  vrp?: string;
  abonement?: Abonement;
}

export interface AbonementsGrantsRequest {
  status?: AbonementsGrantsRequestStatus;
  abonementsUuids?: string[];
  query?: string;
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
}

export enum AbonementsGrantsRequestStatus {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  STATUS_FUTURE = "STATUS_FUTURE",
  STATUS_PAST = "STATUS_PAST",
  STATUS_CURRENT = "STATUS_CURRENT",
  STATUS_ALL = "STATUS_ALL"
}

export interface AbonementsGrantsResponse {
  grants?: AbonementGrant[];
  /** @format int32 */
  total?: number;
}

export interface AbonementsResponse {
  abonements?: Abonement[];
}

export interface Address {
  /**
   * страна
   * - COUNTRY_UNSPECIFIED: не установлено
   *  - COUNTRY_RUSSIA: Россия
   */
  country?: Country;
  /**
   * город
   * - CITY_UNSPECIFIED: не установлено
   *  - CITY_SOCHI: Сочи
   */
  city?: City;
  /** адрес */
  street?: Record<string, string>;
  /** дом */
  house?: Record<string, string>;
}

export interface Benefit {
  /** uuid сущности */
  uuid?: Uuid;
  name?: Record<string, string>;
  /**
   * скидка от стоимости часа
   * @format int32
   */
  discountPercent?: number;
  active?: boolean;
  periods?: BenefitPeriod[];
  /**
   * количество активных назначений
   * @format int32
   */
  currentUsesCount?: number;
}

export interface BenefitPeriod {
  /**
   * время действия С. Пример (00:00): 0
   * @format int32
   */
  start?: number;
  /**
   * время действия По. Пример (23:59): 46800
   * @format int32
   */
  end?: number;
  /** Время действия по дням недели. 1 - Понедельник, 2 - Вт. и т.д. */
  dayOfWeeks?: number[];
  /** Время действия по дням месяца. 1 - 31. */
  days?: number[];
}

export interface BenefitsGrant {
  /** uuid сущности */
  uuid?: Uuid;
  /**
   * дата начала действия 1664571600000
   * @format int64
   */
  start?: string;
  /**
   * дата окончания действия 1664571600000
   * @format int64
   */
  end?: string;
  benefitUuid?: Uuid;
  /**
   * дата создания 1664571600000
   * @format int64
   */
  createdAt?: string;
  vrp?: string;
  zones?: string[];
  benefit?: Benefit;
}

export interface BenefitsGrantResponse {
  grant?: BenefitsGrant;
}

export interface BenefitsGrantsRequest {
  status?: BenefitsGrantsRequestStatus;
  benefitUuids?: string[];
  query?: string;
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
}

export enum BenefitsGrantsRequestStatus {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  STATUS_FUTURE = "STATUS_FUTURE",
  STATUS_PAST = "STATUS_PAST",
  STATUS_CURRENT = "STATUS_CURRENT",
  STATUS_ALL = "STATUS_ALL"
}

export interface BenefitsGrantsResponse {
  grants?: BenefitsGrant[];
  /** @format int32 */
  total?: number;
}

export interface BenefitsResponse {
  benefits?: Benefit[];
}

export interface Category {
  uuid?: Uuid;
  name?: Record<string, string>;
  children?: SubCategory[];
}

export interface CategoryResponse {
  categories?: Category[];
}

/**
* - CITY_UNSPECIFIED: не установлено
 - CITY_SOCHI: Сочи
*/
export enum City {
  CITY_UNSPECIFIED = "CITY_UNSPECIFIED",
  CITY_SOCHI = "CITY_SOCHI"
}

export interface Company {
  uuid?: string;
  name?: string;
  innOrBik?: string;
  email?: string;
  contactPhone?: string;
  /** ФИО руководителя - допустим ввод букв, спец.символов, кол-во = 150 символов */
  supervisorFio?: string;
  /** Должность руководителя - допустим ввод букв, цифр, спец.символов, кол-во = 150 символов */
  supervisorPosition?: string;
  /** Примечание - дополнительная информация по организации, допустим ввод букв, цифр, спец.символов, кол-во = 300 символов */
  note?: string;
  legalAddress?: string;
  postalAddress?: string;
  /**
   * Баланс компании
   * @format uint64
   */
  amount?: string;
  /** Телефон супервизора */
  supervisorPhone?: string;
}

export interface ConfigResponse {
  subCategoryTypes?: ConfigResponseSubCategoryTypeWithTranslate[];
  subCategoryZoneTypes?: ConfigResponseSubCategoryZoneTypeWithTranslate[];
  cities?: ConfigResponseCityWithTranslate[];
  zoneTypes?: ConfigResponseZoneTypeWithTranslate[];
  vehicleTypes?: ConfigResponseVehicleTypeWithTranslate[];
  gisTypes?: ConfigResponseGisTypeWithTranslate[];
  countries?: ConfigResponseCountryWithTranslate[];
  userStatuses?: ConfigResponseUserStatusWithTranslate[];
  holidays?: ConfigResponseHolidayTypeWithTranslate[];
  vehicles?: ConfigResponseVehicleReference[];
  availableLanguages?: Language[];
  mapSettings?: MapSettings;
}

export interface ConfigResponseCityWithTranslate {
  /**
   * - CITY_UNSPECIFIED: не установлено
   *  - CITY_SOCHI: Сочи
   */
  code?: City;
  name?: Record<string, string>;
}

export interface ConfigResponseCountryWithTranslate {
  /**
   * - COUNTRY_UNSPECIFIED: не установлено
   *  - COUNTRY_RUSSIA: Россия
   */
  code?: Country;
  name?: Record<string, string>;
}

export interface ConfigResponseGisTypeWithTranslate {
  /**
   * - GIS_TYPE_UNSPECIFIED: не установлено
   *  - GIS_TYPE_POLYGON: Полигон
   *  - GIS_TYPE_POINT: Точка
   *  - GIS_TYPE_LINE: Линия
   */
  code?: GisType;
  name?: Record<string, string>;
}

export interface ConfigResponseHolidayTypeWithTranslate {
  /**
   * - HOLIDAY_TYPE_UNSPECIFIED: не установлено
   *  - HOLIDAY_TYPE_DAYOFF: Выходной день
   *  - HOLIDAY_TYPE_HOLIDAY: Праздник
   */
  code?: HolidayType;
  name?: Record<string, string>;
}

export interface ConfigResponseSubCategoryTypeWithTranslate {
  /**
   * - SUB_CATEGORY_TYPE_UNSPECIFIED: не установлено
   *  - SUB_CATEGORY_TYPE_PARKINGS: парковка
   *  - SUB_CATEGORY_TYPE_PARKOMATS: паркомат
   *  - SUB_CATEGORY_TYPE_TERMINALS: терминал
   *  - SUB_CATEGORY_TYPE_SCOREBOARDS: инфотабло
   */
  code?: SubCategoryType;
  name?: Record<string, string>;
}

export interface ConfigResponseSubCategoryZoneTypeWithTranslate {
  /**
   * - SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED: не установлено
   *  - SUB_CATEGORY_ZONE_TYPE_PAID: платная
   *  - SUB_CATEGORY_ZONE_TYPE_FREE: бесплатная
   */
  code?: SubCategoryZoneType;
  name?: Record<string, string>;
}

export interface ConfigResponseUserStatusWithTranslate {
  /**
   * - USER_STATUS_UNSPECIFIED: не установлено
   *  - USER_STATUS_ACTIVE: активный
   *  - USER_STATUS_BLOCKED: заблокированный
   */
  code?: UserStatus;
  name?: Record<string, string>;
}

export interface ConfigResponseVehicleReference {
  /**
   * Тип ТС, Например: car
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  type?: VehicleType;
  /** регулярное выражения для проверки ГРЗ */
  vrpRegexp?: string;
  /** если нажата галочка: Иностранный - Прочий */
  foreignVrpRegexp?: string;
  /** Название */
  title?: Record<string, string>;
  /** Маска для инпута: X XXX XX XXX */
  vrpMask?: string;
  /** Маска для инпута: A 000 AA 000 */
  placeholder?: string;
}

export interface ConfigResponseVehicleTypeWithTranslate {
  /**
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  code?: VehicleType;
  name?: Record<string, string>;
}

export interface ConfigResponseZoneTypeWithTranslate {
  /**
   * - ZONE_TYPE_UNSPECIFIED: не установлено
   *  - ZONE_TYPE_SIMPLE: Уличная парковка (УДС)
   *  - ZONE_TYPE_WITH_PERMISSION: Парковка с разрешениями
   *  - ZONE_TYPE_PRIVATE: Приватная закрытая парковка
   *  - ZONE_TYPE_GATED: Плоскостная парковка (ППЗТ)
   *  - ZONE_TYPE_CLOSED: Коммерческая парковка
   */
  code?: ZoneType;
  name?: Record<string, string>;
}

/**
* - COUNTRY_UNSPECIFIED: не установлено
 - COUNTRY_RUSSIA: Россия
*/
export enum Country {
  COUNTRY_UNSPECIFIED = "COUNTRY_UNSPECIFIED",
  COUNTRY_RUSSIA = "COUNTRY_RUSSIA"
}

export interface CreateAbonementsGrantRequest {
  /**
   * дата начала действия 1664571600000
   * @format int64
   */
  start?: string;
  /**
   * дата окончания действия 1664571600000
   * @format int64
   */
  end?: string;
  abonementUuid?: Uuid;
  vrp?: string;
}

export interface CreateBenefitsGrantRequest {
  /**
   * дата начала действия 1664571600000
   * @format int64
   */
  start?: string;
  /**
   * дата окончания действия 1664571600000
   * @format int64
   */
  end?: string;
  benefitUuid?: Uuid;
  vrp?: string;
  zones?: string[];
}

export interface CreateCategoryRequest {
  /** название категории с поддержкой интернационализации */
  name?: Record<string, string>;
}

export interface CreateDefaultResponse {
  uuid?: Uuid;
}

export interface CreateHolidayRequest {
  /**
   * дата. всегда 00:00:00. пример: 1640984400000.
   * @format int64
   */
  date?: string;
  /**
   * - HOLIDAY_TYPE_UNSPECIFIED: не установлено
   *  - HOLIDAY_TYPE_DAYOFF: Выходной день
   *  - HOLIDAY_TYPE_HOLIDAY: Праздник
   */
  type?: HolidayType;
  title?: string;
}

export interface CreateParkingRequest {
  /** Должно быть только одно из значений, в зависимости от type */
  location?: ParkingLocation;
  name?: Record<string, string>;
  description?: Record<string, string>;
  contacts?: Record<string, string>;
  address?: Address;
  center?: Point;
  /** uuid зоны */
  zoneUuid?: Uuid;
  /** uuid категории */
  categoryUuid?: Uuid;
  /** @format int32 */
  spacesTotal?: number;
  /** @format int32 */
  spacesHandicapped?: number;
}

export interface CreatePermissionsGrantRequest {
  /** Разрешение */
  permissionUuid?: string;
  /** транспортное средство */
  vrp?: string;
  /**
   * Тип транспортного средства:
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  vehicleType?: VehicleType;
  /**
   * дата начала действия
   * @format int64
   */
  start?: string;
  /**
   * дата окончания действия
   * @format int64
   */
  end?: string;
}

export interface CreateSubCategoryRequest {
  /** uuid родительской категории */
  parentUuid?: Uuid;
  /** название подкатегории с поддержкой интернационализации */
  name?: Record<string, string>;
  /**
   * - SUB_CATEGORY_TYPE_UNSPECIFIED: не установлено
   *  - SUB_CATEGORY_TYPE_PARKINGS: парковка
   *  - SUB_CATEGORY_TYPE_PARKOMATS: паркомат
   *  - SUB_CATEGORY_TYPE_TERMINALS: терминал
   *  - SUB_CATEGORY_TYPE_SCOREBOARDS: инфотабло
   */
  type?: SubCategoryType;
  /**
   * - SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED: не установлено
   *  - SUB_CATEGORY_ZONE_TYPE_PAID: платная
   *  - SUB_CATEGORY_ZONE_TYPE_FREE: бесплатная
   */
  zoneType?: SubCategoryZoneType;
}

export interface CreateZoneRequest {
  /** номер зоны */
  number?: string;
  /**
   * тип зоны
   * - ZONE_TYPE_UNSPECIFIED: не установлено
   *  - ZONE_TYPE_SIMPLE: Уличная парковка (УДС)
   *  - ZONE_TYPE_WITH_PERMISSION: Парковка с разрешениями
   *  - ZONE_TYPE_PRIVATE: Приватная закрытая парковка
   *  - ZONE_TYPE_GATED: Плоскостная парковка (ППЗТ)
   *  - ZONE_TYPE_CLOSED: Коммерческая парковка
   */
  type?: ZoneType;
  /** описание зоны */
  description?: Record<string, string>;
  /**
   * город
   * - CITY_UNSPECIFIED: не установлено
   *  - CITY_SOCHI: Сочи
   */
  city?: City;
  /** Полигон покрытия зоны */
  location?: Polygon;
  /** центр полигона */
  center?: Point;
  /** включить - выключить зону */
  isActive?: boolean;
  /** цены в зависимости от типа ТС */
  prices?: VehiclePrice[];
  /** uuid тарифа */
  tariffUuid?: Uuid;
}

export interface Duration {
  /** @format int32 */
  years?: number;
  /** @format int32 */
  months?: number;
  /** @format int32 */
  days?: number;
}

export interface EnvVersion {
  build?: string;
  release?: string;
  strict?: boolean;
}

export interface GetCompaniesResponse {
  companies?: Company[];
  /** @format int32 */
  total?: number;
}

export interface GetHolidayByYearResponse {
  holidays?: Holiday[];
}

export interface GetHolidaysResponse {
  holidays?: GetHolidaysResponseYearStat[];
}

export interface GetHolidaysResponseYearStat {
  /** @format int32 */
  year?: number;
  counts?: YearStatYearStatCounts;
}

export interface GetParkingResponse {
  parking?: Parking;
}

export interface GetParkingsResponse {
  parkings?: Parking[];
  /** @format int32 */
  total?: number;
}

export interface GetPushLogResponse {
  /** @format uint64 */
  total?: string;
  pushEvents?: PushLog[];
}

export interface GetRoleListResponse {
  roles?: Role[];
}

export interface GetSingleTariffResponse {
  tariff?: Tariff;
}

export interface GetSmsLogResponse {
  /** @format uint64 */
  total?: string;
  smsEvents?: SmsEvent[];
}

export interface GetTariffResponse {
  tariffs?: Tariff[];
}

export interface GetUserListResponse {
  users?: User[];
  /** @format int32 */
  total?: number;
}

export interface GetUserResponse {
  user?: User;
}

export interface GetZoneResponse {
  zone?: Zone;
}

export interface GetZonesResponse {
  zones?: Zone[];
  /** @format int32 */
  total?: number;
}

/**
* - GIS_TYPE_UNSPECIFIED: не установлено
 - GIS_TYPE_POLYGON: Полигон
 - GIS_TYPE_POINT: Точка
 - GIS_TYPE_LINE: Линия
*/
export enum GisType {
  GIS_TYPE_UNSPECIFIED = "GIS_TYPE_UNSPECIFIED",
  GIS_TYPE_POLYGON = "GIS_TYPE_POLYGON",
  GIS_TYPE_POINT = "GIS_TYPE_POINT",
  GIS_TYPE_LINE = "GIS_TYPE_LINE"
}

export interface HealthCheckResponse {
  /** @format int64 */
  ttl?: string;
}

export interface Holiday {
  /** uuid сущности */
  uuid?: Uuid;
  /**
   * дата. всегда 00:00:00. пример: 1640984400000.
   * @format int64
   */
  date?: string;
  /**
   * - HOLIDAY_TYPE_UNSPECIFIED: не установлено
   *  - HOLIDAY_TYPE_DAYOFF: Выходной день
   *  - HOLIDAY_TYPE_HOLIDAY: Праздник
   */
  type?: HolidayType;
  title?: string;
}

/**
* - HOLIDAY_TYPE_UNSPECIFIED: не установлено
 - HOLIDAY_TYPE_DAYOFF: Выходной день
 - HOLIDAY_TYPE_HOLIDAY: Праздник
*/
export enum HolidayType {
  HOLIDAY_TYPE_UNSPECIFIED = "HOLIDAY_TYPE_UNSPECIFIED",
  HOLIDAY_TYPE_DAYOFF = "HOLIDAY_TYPE_DAYOFF",
  HOLIDAY_TYPE_HOLIDAY = "HOLIDAY_TYPE_HOLIDAY"
}

export interface Language {
  shortCode?: string;
  localizedName?: string;
}

export interface License {
  untilDate?: string;
  entityCount?: string;
  rectangle?: string;
}

export interface Line {
  /** 2 точки описывающае линию. длина массива всегда должна быть >= 2 */
  coordinates?: Point[];
}

export interface LoginRequest {
  email?: string;
  password?: string;
}

export interface LoginResponse {
  accessToken?: string;
  refreshToken?: string;
  /** @format int64 */
  expiresIn?: string;
  permissions?: LoginResponsePermission[];
}

export interface LoginResponsePermission {
  name?: string;
  scopes?: string[];
}

export interface MapSettings {
  /**
   * Долгота
   * @format double
   */
  defaultMapCenterLongitude?: number;
  /**
   * Широта
   * @format double
   */
  defaultMapCenterLatitude?: number;
  engine?: string;
  controls?: string[];
}

export interface MinMaxPrice {
  /**
   * минимальная цена в коп
   * @format int32
   */
  min?: number;
  /**
   * максимальная цена в коп
   * @format int32
   */
  max?: number;
}

export interface Parking {
  /** uuid парковки */
  uuid?: Uuid;
  /** Должно быть только одно из значений, в зависимости от type */
  location?: ParkingLocation;
  name?: Record<string, string>;
  description?: Record<string, string>;
  contacts?: Record<string, string>;
  address?: Address;
  center?: Point;
  /** uuid зоны */
  zoneUuid?: Uuid;
  /** uuid категории */
  categoryUuid?: Uuid;
  /** @format int32 */
  spacesTotal?: number;
  /** @format int32 */
  spacesHandicapped?: number;
}

/**
 * Должно быть только одно из значений, в зависимости от type
 */
export interface ParkingLocation {
  /**
   * - GIS_TYPE_UNSPECIFIED: не установлено
   *  - GIS_TYPE_POLYGON: Полигон
   *  - GIS_TYPE_POINT: Точка
   *  - GIS_TYPE_LINE: Линия
   */
  type?: GisType;
  point?: Point;
  polygon?: Polygon;
  line?: Line;
}

export interface ParkingsWithClustersRequest {
  /** поисковая фраза */
  search?: string;
  /** координаты экрана */
  rectangle?: Rectangle;
  /** @format int32 */
  zoom?: number;
}

export interface ParkingsWithClustersResponse {
  parkings?: Parking[];
  clusters?: ParkingsWithClustersResponseCluster[];
}

export interface ParkingsWithClustersResponseCluster {
  center?: Point;
  /** @format int32 */
  count?: number;
  rectangle?: Rectangle;
}

export interface Permission {
  uuid?: Uuid;
  /** ресурс */
  object?: string;
  /** доступные действия */
  scopes?: string[];
}

export interface PermissionCreateRequest {
  /** Название */
  name?: Record<string, string>;
  /**
   * цена
   * @format int64
   */
  price?: string;
  /** Время действия по дням недели. 1 - Понедельник, 2 - Вт. и т.д. */
  dayOfWeeks?: number[];
  /**
   * время действия С. Пример (00:00): 0
   * @format int32
   */
  start?: number;
  /**
   * время действия По. Пример (23:59): 46800
   * @format int32
   */
  end?: number;
  /** Количество лет/месяцев/дней */
  duration?: Duration;
  /** Начало действия назначения после приобретения */
  startType?: StartType;
  /** Тип тарификации */
  tarificationType?: string;
  /** номера зон */
  zones?: string[];
}

export interface PermissionDefaultResponse {
  uuid?: string;
}

export interface PermissionFull {
  /** uuid сущности */
  uuid?: string;
  /** Название */
  name?: Record<string, string>;
  /**
   * цена
   * @format int64
   */
  price?: string;
  /** Время действия по дням недели. 1 - Понедельник, 2 - Вт. и т.д. */
  dayOfWeeks?: number[];
  /**
   * время действия С. Пример (00:00): 0
   * @format int32
   */
  start?: number;
  /**
   * время действия По. Пример (23:59): 46800
   * @format int32
   */
  end?: number;
  /** Количество лет/месяцев/дней */
  duration?: Duration;
  /** Начало действия назначения после приобретения */
  startType?: StartType;
  /** Тип тарификации */
  tarificationType?: string;
  /** номера зон */
  zones?: string[];
  /**
   * количество выданных разрешений
   * @format int32
   */
  currentUsesCount?: number;
  isDeleted?: boolean;
}

export interface PermissionGrant {
  /** uuid сущности */
  uuid?: string;
  /**
   * Тип транспортного средства:
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  vehicleType?: VehicleType;
  /** транспортное средство */
  vrp?: string;
  /**
   * дата начала действия
   * @format int64
   */
  start?: string;
  /**
   * дата окончания действия
   * @format int64
   */
  end?: string;
  /** uiid разрешение */
  permissionUuid?: string;
  /**
   * дата создания
   * @format int64
   */
  createdAt?: string;
  /** Разрешение */
  permission?: PermissionFull;
}

export interface PermissionsGrantsRequest {
  status?: PermissionsGrantsRequestStatus;
  permissionsUuids?: string[];
  query?: string;
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
  vrps?: string[];
}

export enum PermissionsGrantsRequestStatus {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  STATUS_FUTURE = "STATUS_FUTURE",
  STATUS_PAST = "STATUS_PAST",
  STATUS_CURRENT = "STATUS_CURRENT",
  STATUS_ALL = "STATUS_ALL"
}

export interface PermissionsGrantsResponse {
  grants?: PermissionGrant[];
  /** @format int32 */
  total?: number;
}

export interface PermissionsResponse {
  permissions?: PermissionFull[];
}

export interface Point {
  /** @format float */
  longitude?: number;
  /** @format float */
  lattitude?: number;
}

export interface Polygon {
  coordinates?: Point[];
}

export interface ProfileResponse {
  user?: User;
}

export interface PushLog {
  uuid?: string;
  userUuid?: string;
  text?: string;
  provider?: string;
  status?: string;
  errorDetails?: string;
  /** @format int64 */
  createdAt?: string;
}

export interface Rectangle {
  /** @format double */
  leftTopLongitude?: number;
  /** @format double */
  leftTopLatitude?: number;
  /** @format double */
  rightBottomLongitude?: number;
  /** @format double */
  rightBottomLatitude?: number;
}

export type RefillCompanyResponse = object;

export interface RefreshTokenRequest {
  refreshToken?: string;
}

export interface RoadsideSession {
  /** uuid сессии */
  uuid?: string;
  /** uuid пользователя */
  userUuid?: string;
  /** парковка */
  parking?: Parking;
  /** транспортное средство */
  vehicle?: Vehicle;
  /**
   * время старта, unix timestamp (миллисекунды)
   * @format int64
   */
  startTime?: string;
  /**
   * время остановки, unix timestamp (миллисекунды)
   * @format int64
   */
  stopTime?: string;
  /**
   * статус
   * - ROADSIDE_SESSION_STATUS_UNSPECIFIED: не установлено
   *  - ROADSIDE_SESSION_STATUS_START: инициализирована
   *  - ROADSIDE_SESSION_STATUS_ACTIVE: активна
   *  - ROADSIDE_SESSION_STATUS_STOP: остановлена
   *  - ROADSIDE_SESSION_STATUS_END: завершена
   *  - ROADSIDE_SESSION_STATUS_CANCEL: отменена
   */
  status?: RoadsideSessionStatus;
}

/**
* - ROADSIDE_SESSION_STATUS_UNSPECIFIED: не установлено
 - ROADSIDE_SESSION_STATUS_START: инициализирована
 - ROADSIDE_SESSION_STATUS_ACTIVE: активна
 - ROADSIDE_SESSION_STATUS_STOP: остановлена
 - ROADSIDE_SESSION_STATUS_END: завершена
 - ROADSIDE_SESSION_STATUS_CANCEL: отменена
*/
export enum RoadsideSessionStatus {
  ROADSIDE_SESSION_STATUS_UNSPECIFIED = "ROADSIDE_SESSION_STATUS_UNSPECIFIED",
  ROADSIDE_SESSION_STATUS_START = "ROADSIDE_SESSION_STATUS_START",
  ROADSIDE_SESSION_STATUS_ACTIVE = "ROADSIDE_SESSION_STATUS_ACTIVE",
  ROADSIDE_SESSION_STATUS_STOP = "ROADSIDE_SESSION_STATUS_STOP",
  ROADSIDE_SESSION_STATUS_END = "ROADSIDE_SESSION_STATUS_END",
  ROADSIDE_SESSION_STATUS_CANCEL = "ROADSIDE_SESSION_STATUS_CANCEL"
}

export interface RoadsideSessionsResponse {
  /** @format uint64 */
  total?: string;
  sessions?: RoadsideSession[];
}

export interface Role {
  uuid?: Uuid;
  /** название роли с переводом */
  name?: Record<string, string>;
  /** набор возможностей роли */
  permissions?: Permission[];
  /** область действия роли (пока не используется) */
  realm?: string;
}

export interface SmsEvent {
  uuid?: string;
  phone?: string;
  text?: string;
  provider?: string;
  externalId?: string;
  status?: string;
  errorDetails?: string;
  /** @format int64 */
  createdAt?: string;
}

export enum StartType {
  START_TYPE_UNSPECIFIED = "START_TYPE_UNSPECIFIED",
  START_TYPE_FROMTOMORROW = "START_TYPE_FROMTOMORROW",
  START_TYPE_FROMTODAY = "START_TYPE_FROMTODAY",
  START_TYPE_FROMYESTERDAY = "START_TYPE_FROMYESTERDAY"
}

export interface SubCategory {
  uuid?: Uuid;
  parentUuid?: Uuid;
  name?: Record<string, string>;
  /**
   * - SUB_CATEGORY_TYPE_UNSPECIFIED: не установлено
   *  - SUB_CATEGORY_TYPE_PARKINGS: парковка
   *  - SUB_CATEGORY_TYPE_PARKOMATS: паркомат
   *  - SUB_CATEGORY_TYPE_TERMINALS: терминал
   *  - SUB_CATEGORY_TYPE_SCOREBOARDS: инфотабло
   */
  type?: SubCategoryType;
  /**
   * - SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED: не установлено
   *  - SUB_CATEGORY_ZONE_TYPE_PAID: платная
   *  - SUB_CATEGORY_ZONE_TYPE_FREE: бесплатная
   */
  zoneType?: SubCategoryZoneType;
}

/**
* - SUB_CATEGORY_TYPE_UNSPECIFIED: не установлено
 - SUB_CATEGORY_TYPE_PARKINGS: парковка
 - SUB_CATEGORY_TYPE_PARKOMATS: паркомат
 - SUB_CATEGORY_TYPE_TERMINALS: терминал
 - SUB_CATEGORY_TYPE_SCOREBOARDS: инфотабло
*/
export enum SubCategoryType {
  SUB_CATEGORY_TYPE_UNSPECIFIED = "SUB_CATEGORY_TYPE_UNSPECIFIED",
  SUB_CATEGORY_TYPE_PARKINGS = "SUB_CATEGORY_TYPE_PARKINGS",
  SUB_CATEGORY_TYPE_PARKOMATS = "SUB_CATEGORY_TYPE_PARKOMATS",
  SUB_CATEGORY_TYPE_TERMINALS = "SUB_CATEGORY_TYPE_TERMINALS",
  SUB_CATEGORY_TYPE_SCOREBOARDS = "SUB_CATEGORY_TYPE_SCOREBOARDS"
}

/**
* - SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED: не установлено
 - SUB_CATEGORY_ZONE_TYPE_PAID: платная
 - SUB_CATEGORY_ZONE_TYPE_FREE: бесплатная
*/
export enum SubCategoryZoneType {
  SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED = "SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED",
  SUB_CATEGORY_ZONE_TYPE_PAID = "SUB_CATEGORY_ZONE_TYPE_PAID",
  SUB_CATEGORY_ZONE_TYPE_FREE = "SUB_CATEGORY_ZONE_TYPE_FREE"
}

export interface SystemSettingsConfig {
  availableLanguages?: Language[];
  currency?: SystemSettingsConfigCurrency;
  vehicles?: SystemSettingsConfigVehicleReference[];
  mapSettings?: MapSettings;
  env?: SystemSettingsConfigEnv;
  /** коэффициенты размера кластера в зависимости от zoomlvl */
  zoomCoefficients?: Record<string, number>;
  /**
   * на каком уровне zoom происходит отключение кластеризации
   * @format int32
   */
  clusterOffZoomThreshold?: number;
  /**
   * значение зума, до которого выводим один кластер со всеми парковками
   * @format int32
   */
  oneClusterZoomThreshold?: number;
  mobileSettings?: SystemSettingsConfigMobileSettings;
}

export interface SystemSettingsConfigCurrency {
  /** буквенный код валюты (ISO 4217). Например: RUB, KZT */
  alphaCode?: string;
  /** циферный код валюты (ISO 4217). Например: 643, 398 */
  numberCode?: string;
  /** utf8 символ валюты. Например: ₽ */
  symbol?: string;
  /** например: {ru: "Руб.", en: "Rub."} */
  title?: Record<string, string>;
}

export interface SystemSettingsConfigEnv {
  /** режим работы приложения production / develop */
  name?: string;
  versions?: Record<string, EnvVersion>;
}

export interface SystemSettingsConfigMobileSettings {
  /** показывать или не показывать кастомную клавиатуру в поле ввода ГРЗ */
  customKeyboardForVrp?: boolean;
  /**
   * максимальная длительность парковочной сессии
   * @format int32
   */
  sessionMaxDurationInHours?: number;
}

export interface SystemSettingsConfigVehicleReference {
  /** Тип ТС, Например: car */
  type?: string;
  /** регулярное выражения для проверки ГРЗ */
  vrpRegexp?: string;
  /** если нажата галочка: Иностранный - Прочий */
  foreignVrpRegexp?: string;
  /** Название */
  title?: Record<string, string>;
  /** Маска для инпута: X XXX XX XXX */
  vrpMask?: string;
  /** Маска для инпута: A 000 AA 000 */
  placeholder?: string;
}

export interface Tariff {
  /** uuid тарифа */
  uuid?: Uuid;
  name?: Record<string, string>;
  description?: Record<string, string>;
  /**
   * город
   * - CITY_UNSPECIFIED: не установлено
   *  - CITY_SOCHI: Сочи
   */
  city?: City;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  zones?: TariffTariffZone[];
  periods?: TariffTariffPeriods;
  ruleGroups?: TariffTariffRuleGroup[];
}

export interface TariffRuleGroupRule {
  /**
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  vehicleType?: VehicleType;
  /** @format int64 */
  start?: string;
  /** @format int64 */
  end?: string;
  /** @format int32 */
  price?: number;
}

export interface TariffTariffPeriod {
  /** @format int64 */
  start?: string;
  /** @format int64 */
  end?: string;
  /** @format int32 */
  ruleGroupIndex?: number;
}

export interface TariffTariffPeriods {
  weekdays?: TariffTariffPeriod[];
  holidays?: TariffTariffPeriod[];
  dayOffs?: TariffTariffPeriod[];
}

export interface TariffTariffRuleGroup {
  rules?: TariffRuleGroupRule[];
}

export interface TariffTariffZone {
  number?: string;
  active?: boolean;
}

export interface User {
  uuid?: Uuid;
  email?: string;
  phone?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  lastActivityDate?: string;
  vehicles?: Vehicle[];
  firstName?: string;
  lastName?: string;
  middleName?: string;
  /** @format date-time */
  birthdate?: string;
  /**
   * - USER_STATUS_UNSPECIFIED: не установлено
   *  - USER_STATUS_ACTIVE: активный
   *  - USER_STATUS_BLOCKED: заблокированный
   */
  status?: UserStatus;
  /** номер платежного аккаунта в нашей системе */
  accountId?: string;
  rolesUuids?: Uuid[];
  isEmployee?: boolean;
}

export interface UserActivitiesResponse {
  /**
   * количество событий, всего
   * @format uint64
   */
  total?: string;
  userActivities?: UserActivity[];
}

export interface UserActivity {
  uuid?: string;
  userUuid?: string;
  eventType?: string;
  eventData?: Record<string, string>;
  action?: string;
  diffData?: Record<string, UserActivityDiffData>;
  /** @format int64 */
  date?: string;
}

export interface UserActivityDiffData {
  fields?: Record<string, string>;
}

/**
 * История событий пользователя.
 */
export interface UserEvent {
  uuid?: string;
  title?: string;
  description?: string;
  /** @format int64 */
  date?: string;
  /** @format int64 */
  amount?: number;
  sign?: string;
  userUuid?: string;
  operationId?: string;
}

export interface UserEventsResponse {
  /**
   * количество событий, всего
   * @format uint64
   */
  total?: string;
  userEvents?: UserEvent[];
}

/**
* - USER_STATUS_UNSPECIFIED: не установлено
 - USER_STATUS_ACTIVE: активный
 - USER_STATUS_BLOCKED: заблокированный
*/
export enum UserStatus {
  USER_STATUS_UNSPECIFIED = "USER_STATUS_UNSPECIFIED",
  USER_STATUS_ACTIVE = "USER_STATUS_ACTIVE",
  USER_STATUS_BLOCKED = "USER_STATUS_BLOCKED"
}

export interface Uuid {
  /** @example "b8619e31-0c91-4501-a79c-1d11b994ba98" */
  value?: string;
}

export interface Vehicle {
  /**
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  type?: VehicleType;
  /**
   * - VRP_FORMAT_UNSPECIFIED: не установлено
   *  - VRP_FORMAT_LOCAL: внутренний
   *  - VRP_FORMAT_FOREIGN: иностранный
   */
  vrpFormat?: VrpFormat;
  vrp?: string;
  label?: string;
  uuid?: string;
}

export interface VehiclePrice {
  /**
   * - VEHICLE_TYPE_UNSPECIFIED: не установлено
   *  - VEHICLE_TYPE_CAR: легковой ТС
   *  - VEHICLE_TYPE_TRUCK: грузовой ТС
   *  - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
   *  - VEHICLE_TYPE_BUS: автобус
   */
  vehicleType?: VehicleType;
  price?: MinMaxPrice;
}

/**
* - VEHICLE_TYPE_UNSPECIFIED: не установлено
 - VEHICLE_TYPE_CAR: легковой ТС
 - VEHICLE_TYPE_TRUCK: грузовой ТС
 - VEHICLE_TYPE_MOTORCYCLE: мотоцикл
 - VEHICLE_TYPE_BUS: автобус
*/
export enum VehicleType {
  VEHICLE_TYPE_UNSPECIFIED = "VEHICLE_TYPE_UNSPECIFIED",
  VEHICLE_TYPE_CAR = "VEHICLE_TYPE_CAR",
  VEHICLE_TYPE_TRUCK = "VEHICLE_TYPE_TRUCK",
  VEHICLE_TYPE_MOTORCYCLE = "VEHICLE_TYPE_MOTORCYCLE",
  VEHICLE_TYPE_BUS = "VEHICLE_TYPE_BUS"
}

/**
* - VRP_FORMAT_UNSPECIFIED: не установлено
 - VRP_FORMAT_LOCAL: внутренний
 - VRP_FORMAT_FOREIGN: иностранный
*/
export enum VrpFormat {
  VRP_FORMAT_UNSPECIFIED = "VRP_FORMAT_UNSPECIFIED",
  VRP_FORMAT_LOCAL = "VRP_FORMAT_LOCAL",
  VRP_FORMAT_FOREIGN = "VRP_FORMAT_FOREIGN"
}

export interface YearStatYearStatCounts {
  /** @format int32 */
  dayOff?: number;
  /** @format int32 */
  holiday?: number;
}

export interface Zone {
  /** uuid зоны */
  uuid?: Uuid;
  /** номер зоны */
  number?: string;
  /**
   * тип зоны
   * - ZONE_TYPE_UNSPECIFIED: не установлено
   *  - ZONE_TYPE_SIMPLE: Уличная парковка (УДС)
   *  - ZONE_TYPE_WITH_PERMISSION: Парковка с разрешениями
   *  - ZONE_TYPE_PRIVATE: Приватная закрытая парковка
   *  - ZONE_TYPE_GATED: Плоскостная парковка (ППЗТ)
   *  - ZONE_TYPE_CLOSED: Коммерческая парковка
   */
  type?: ZoneType;
  /** описание зоны */
  description?: Record<string, string>;
  /**
   * город
   * - CITY_UNSPECIFIED: не установлено
   *  - CITY_SOCHI: Сочи
   */
  city?: City;
  /** Полигон покрытия зоны */
  location?: Polygon;
  /** центр полигона */
  center?: Point;
  /** включить - выключить зону */
  isActive?: boolean;
  /** цены в зависимости от типа ТС */
  prices?: VehiclePrice[];
  /** uuid тарифа */
  tariffUuid?: Uuid;
}

/**
* - ZONE_TYPE_UNSPECIFIED: не установлено
 - ZONE_TYPE_SIMPLE: Уличная парковка (УДС)
 - ZONE_TYPE_WITH_PERMISSION: Парковка с разрешениями
 - ZONE_TYPE_PRIVATE: Приватная закрытая парковка
 - ZONE_TYPE_GATED: Плоскостная парковка (ППЗТ)
 - ZONE_TYPE_CLOSED: Коммерческая парковка
*/
export enum ZoneType {
  ZONE_TYPE_UNSPECIFIED = "ZONE_TYPE_UNSPECIFIED",
  ZONE_TYPE_SIMPLE = "ZONE_TYPE_SIMPLE",
  ZONE_TYPE_WITH_PERMISSION = "ZONE_TYPE_WITH_PERMISSION",
  ZONE_TYPE_PRIVATE = "ZONE_TYPE_PRIVATE",
  ZONE_TYPE_GATED = "ZONE_TYPE_GATED",
  ZONE_TYPE_CLOSED = "ZONE_TYPE_CLOSED"
}

export interface GooglerpcStatus {
  /** @format int32 */
  code?: number;
  message?: string;
  details?: ProtobufAny[];
}

export interface ProtobufAny {
  "@type"?: string;
  [key: string]: any;
}

export interface AdminGatewayGetAbonementParams {
  /** uuid */
  uuid?: string;
}

export interface AdminGatewayGetAbonementGrantParams {
  /** uuid */
  uuid?: string;
}

export interface AdminGatewayGetCompaniesParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
  q?: string;
}

export interface AdminGatewayRefillCompanyParams {
  companyUuid?: string;
  /** @format uint64 */
  amount?: string;
  transactionUuid?: string;
  confirmingDocument?: string;
}

export interface AdminGatewayGetHolidaysParams {
  /**
   * начальная дата. пример: 1640984400000.
   * @format int64
   */
  start?: string;
  /**
   * конечная дата. пример: 1988139599999.
   * @format int64
   */
  end?: string;
}

export interface AdminGatewayGetHolidayByYearParams {
  /**
   * начальная дата. пример: 1640984400000.
   * @format int64
   */
  start?: string;
  /**
   * конечная дата. пример: 1988139599999.
   * @format int64
   */
  end?: string;
}

export interface AdminGatewayParkingsParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
}

export interface AdminGatewayGetPermissionParams {
  /** uuid */
  uuid?: string;
}

export interface AdminGatewayGetPermissionGrantParams {
  /** uuid */
  uuid?: string;
}

export interface AdminGatewayGetRoadsideSessionsParams {
  /**
   * pagination
   * @format uint64
   */
  limit?: string;
  /** @format uint64 */
  offset?: string;
}

export interface AdminGatewayGetPushLogParams {
  /**
   * фильтрами по дате формирования пушей (от)
   * @format int64
   */
  start?: string;
  /**
   * фильтрами по дате формирования пушей (до)
   * @format int64
   */
  end?: string;
  /** @format uint64 */
  limit?: string;
  /** @format uint64 */
  offset?: string;
}

export interface AdminGatewayGetSmsLogParams {
  phone?: string;
  /**
   * фильтрами по дате формирования СМС (от)
   * @format int64
   */
  start?: string;
  /**
   * фильтрами по дате формирования СМС (до)
   * @format int64
   */
  end?: string;
  /** @format uint64 */
  limit?: string;
  /** @format uint64 */
  offset?: string;
}

export interface AdminGatewayGetUserActivitiesParams {
  /** @format uint64 */
  limit?: string;
  /** @format uint64 */
  offset?: string;
  userUuid: string;
}

export interface AdminGatewayGetUserEventsParams {
  /** @format uint64 */
  limit?: string;
  /** @format uint64 */
  offset?: string;
  userUuid: string;
}

export interface AdminGatewayUserListParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
  /** @format date-time */
  activityStartDate?: string;
  /** @format date-time */
  activityEndDate?: string;
  /**
   *  - USER_STATUS_UNSPECIFIED: не установлено
   *  - USER_STATUS_ACTIVE: активный
   *  - USER_STATUS_BLOCKED: заблокированный
   * @default "USER_STATUS_UNSPECIFIED"
   */
  status?: "USER_STATUS_UNSPECIFIED" | "USER_STATUS_ACTIVE" | "USER_STATUS_BLOCKED";
  isEmployee?: boolean;
  phone?: string;
}

export interface AdminGatewayZonesParams {
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  offset?: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import qs from "qs";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded"
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  public interceptors;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
    this.interceptors = this.instance.interceptors;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {})
      },
      params: query,
      responseType: responseFormat,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
      data: body,
      url: path
    });
  };
}

/**
 * @title admin_gateway_v1.proto
 * @version version not set
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  healthcheck = {
    /**
     * No description
     *
     * @name AdminGatewayHealthcheck
     * @request GET:/healthcheck
     */
    adminGatewayHealthcheck: (params: RequestParams = {}) =>
      this.request<HealthCheckResponse, GooglerpcStatus>({
        path: `/healthcheck`,
        method: "GET",
        format: "json",
        ...params
      })
  };
  license = {
    /**
     * No description
     *
     * @name AdminGatewayGetCurrentLicense
     * @summary Получение лицензионных ограничений
     * @request GET:/license
     */
    adminGatewayGetCurrentLicense: (params: RequestParams = {}) =>
      this.request<License, GooglerpcStatus>({
        path: `/license`,
        method: "GET",
        format: "json",
        ...params
      })
  };
  v1 = {
    /**
     * No description
     *
     * @name AdminGatewayGetAbonement
     * @summary Получение конкретного абонемента
     * @request GET:/v1/abonement
     */
    adminGatewayGetAbonement: (query: AdminGatewayGetAbonementParams, params: RequestParams = {}) =>
      this.request<Abonement, GooglerpcStatus>({
        path: `/v1/abonement`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayAbonements
     * @summary Справочник абонементов
     * @request GET:/v1/abonements
     */
    adminGatewayAbonements: (params: RequestParams = {}) =>
      this.request<AbonementsResponse, GooglerpcStatus>({
        path: `/v1/abonements`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreateAbonement
     * @summary Добавление абонементов
     * @request PUT:/v1/abonements
     */
    adminGatewayCreateAbonement: (body: AbonementCreateRequest, params: RequestParams = {}) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/abonements`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetAbonementGrant
     * @summary Получение конкретного назначения.
     * @request GET:/v1/abonements/grant
     */
    adminGatewayGetAbonementGrant: (
      query: AdminGatewayGetAbonementGrantParams,
      params: RequestParams = {}
    ) =>
      this.request<AbonementGrant, GooglerpcStatus>({
        path: `/v1/abonements/grant`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayAbonementGrants
     * @summary Поиск назначения абонементов.
     * @request POST:/v1/abonements/grants
     */
    adminGatewayAbonementGrants: (body: AbonementsGrantsRequest, params: RequestParams = {}) =>
      this.request<AbonementsGrantsResponse, GooglerpcStatus>({
        path: `/v1/abonements/grants`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreateAbonementGrant
     * @summary Создание назначения абонемента
     * @request PUT:/v1/abonements/grants
     */
    adminGatewayCreateAbonementGrant: (
      body: CreateAbonementsGrantRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/abonements/grants`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeleteAbonementGrant
     * @summary Удаление назначения абонемента
     * @request DELETE:/v1/abonements/grants/{uuid}
     */
    adminGatewayDeleteAbonementGrant: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/abonements/grants/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeleteAbonement
     * @summary Удаление абонементов
     * @request DELETE:/v1/abonements/{uuid}
     */
    adminGatewayDeleteAbonement: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/abonements/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayEditAbonement
     * @summary Редактирование абонементов
     * @request PATCH:/v1/abonements/{uuid}
     */
    adminGatewayEditAbonement: (
      uuid: string,
      body: {
        name?: Record<string, string>;
        zones?: string[];
        duration?: Duration;
        startType?: StartType;
        /**
         * цена в руб. только целое
         * @format int64
         */
        price?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/abonements/${uuid}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayLogin
     * @request POST:/v1/auth/login
     */
    adminGatewayLogin: (body: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, GooglerpcStatus>({
        path: `/v1/auth/login`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayProfile
     * @summary Получение профиля текущего пользователя, проверка токена
     * @request GET:/v1/auth/profile
     */
    adminGatewayProfile: (params: RequestParams = {}) =>
      this.request<ProfileResponse, GooglerpcStatus>({
        path: `/v1/auth/profile`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayRefreshToken
     * @summary Обновление токена
     * @request POST:/v1/auth/refresh
     */
    adminGatewayRefreshToken: (body: RefreshTokenRequest, params: RequestParams = {}) =>
      this.request<LoginResponse, GooglerpcStatus>({
        path: `/v1/auth/refresh`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayBenefits
     * @summary Справочник льгот
     * @request GET:/v1/benefits
     */
    adminGatewayBenefits: (params: RequestParams = {}) =>
      this.request<BenefitsResponse, GooglerpcStatus>({
        path: `/v1/benefits`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayBenefitGrants
     * @summary Поиск назначения льгот.
     * @request POST:/v1/benefits/grants
     */
    adminGatewayBenefitGrants: (body: BenefitsGrantsRequest, params: RequestParams = {}) =>
      this.request<BenefitsGrantsResponse, GooglerpcStatus>({
        path: `/v1/benefits/grants`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreateBenefitGrant
     * @summary Создание назначения льготы
     * @request PUT:/v1/benefits/grants
     */
    adminGatewayCreateBenefitGrant: (
      body: CreateBenefitsGrantRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/benefits/grants`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayBenefitGrant
     * @summary Поиск назначения льгот.
     * @request GET:/v1/benefits/grants/{uuid}
     */
    adminGatewayBenefitGrant: (uuid: string, params: RequestParams = {}) =>
      this.request<BenefitsGrantResponse, GooglerpcStatus>({
        path: `/v1/benefits/grants/${uuid}`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeleteBenefitGrant
     * @summary Удаление назначения льготы
     * @request DELETE:/v1/benefits/grants/{uuid}
     */
    adminGatewayDeleteBenefitGrant: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/benefits/grants/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCategories
     * @summary Получение списка категорий и подкатегорий объектов
     * @request GET:/v1/categories
     */
    adminGatewayCategories: (params: RequestParams = {}) =>
      this.request<CategoryResponse, GooglerpcStatus>({
        path: `/v1/categories`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreateCategory
     * @summary Добавление категории
     * @request PUT:/v1/categories
     */
    adminGatewayCreateCategory: (body: CreateCategoryRequest, params: RequestParams = {}) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/categories`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreateSubCategory
     * @summary Добавление подкатегории
     * @request PUT:/v1/categories/subcategory
     */
    adminGatewayCreateSubCategory: (body: CreateSubCategoryRequest, params: RequestParams = {}) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/categories/subcategory`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeleteSubCategory
     * @summary Удаление подкатегории по uuid
     * @request DELETE:/v1/categories/subcategory/{uuid}
     */
    adminGatewayDeleteSubCategory: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/categories/subcategory/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayEditSubCategory
     * @summary Редактирование подкатегории по uuid
     * @request PATCH:/v1/categories/subcategory/{uuid}
     */
    adminGatewayEditSubCategory: (
      uuid: string,
      body: {
        /** название подкатегории с поддержкой интернационализации */
        name?: Record<string, string>;
        /**
         * - SUB_CATEGORY_TYPE_UNSPECIFIED: не установлено
         *  - SUB_CATEGORY_TYPE_PARKINGS: парковка
         *  - SUB_CATEGORY_TYPE_PARKOMATS: паркомат
         *  - SUB_CATEGORY_TYPE_TERMINALS: терминал
         *  - SUB_CATEGORY_TYPE_SCOREBOARDS: инфотабло
         */
        type?: SubCategoryType;
        /**
         * - SUB_CATEGORY_ZONE_TYPE_UNSPECIFIED: не установлено
         *  - SUB_CATEGORY_ZONE_TYPE_PAID: платная
         *  - SUB_CATEGORY_ZONE_TYPE_FREE: бесплатная
         */
        zoneType?: SubCategoryZoneType;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/categories/subcategory/${uuid}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeleteCategory
     * @summary Удаление категории по uuid
     * @request DELETE:/v1/categories/{uuid}
     */
    adminGatewayDeleteCategory: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/categories/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayEditCategory
     * @summary Редактирование категории по uuid
     * @request PATCH:/v1/categories/{uuid}
     */
    adminGatewayEditCategory: (
      uuid: string,
      body: {
        name?: Record<string, string>;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/categories/${uuid}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetCompanies
     * @summary Список компаний
     * @request GET:/v1/companies
     */
    adminGatewayGetCompanies: (query: AdminGatewayGetCompaniesParams, params: RequestParams = {}) =>
      this.request<GetCompaniesResponse, GooglerpcStatus>({
        path: `/v1/companies`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayRefillCompany
     * @summary Пополнение компании
     * @request POST:/v1/company/refill
     */
    adminGatewayRefillCompany: (
      query: AdminGatewayRefillCompanyParams,
      params: RequestParams = {}
    ) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/company/refill`,
        method: "POST",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayConfig
     * @summary Получение конфига для админки (переводы, настройки и т.п.)
     * @request GET:/v1/config
     */
    adminGatewayConfig: (params: RequestParams = {}) =>
      this.request<ConfigResponse, GooglerpcStatus>({
        path: `/v1/config`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetHolidays
     * @summary Получение статистики выходных дней и праздников по годам
     * @request GET:/v1/holidays
     */
    adminGatewayGetHolidays: (query: AdminGatewayGetHolidaysParams, params: RequestParams = {}) =>
      this.request<GetHolidaysResponse, GooglerpcStatus>({
        path: `/v1/holidays`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreateHoliday
     * @summary Создание выходного дня или праздника по году
     * @request PUT:/v1/holidays
     */
    adminGatewayCreateHoliday: (body: CreateHolidayRequest, params: RequestParams = {}) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/holidays`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetHolidayByYear
     * @summary Получение выходных дней и праздников по году
     * @request GET:/v1/holidays/year
     */
    adminGatewayGetHolidayByYear: (
      query: AdminGatewayGetHolidayByYearParams,
      params: RequestParams = {}
    ) =>
      this.request<GetHolidayByYearResponse, GooglerpcStatus>({
        path: `/v1/holidays/year`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeleteHoliday
     * @summary Редактирование выходного дня или праздника по году
     * @request DELETE:/v1/holidays/{uuid}
     */
    adminGatewayDeleteHoliday: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/holidays/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayEditHoliday
     * @summary Редактирование выходного дня или праздника по году
     * @request PATCH:/v1/holidays/{uuid}
     */
    adminGatewayEditHoliday: (
      uuid: string,
      body: {
        /**
         * дата. всегда 00:00:00. пример: 1640984400000.
         * @format int64
         */
        date?: string;
        /**
         * - HOLIDAY_TYPE_UNSPECIFIED: не установлено
         *  - HOLIDAY_TYPE_DAYOFF: Выходной день
         *  - HOLIDAY_TYPE_HOLIDAY: Праздник
         */
        type?: HolidayType;
        title?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/holidays/${uuid}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayParkings
     * @summary Получение списка парковок
     * @request GET:/v1/parkings
     */
    adminGatewayParkings: (query: AdminGatewayParkingsParams, params: RequestParams = {}) =>
      this.request<GetParkingsResponse, GooglerpcStatus>({
        path: `/v1/parkings`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreateParking
     * @summary Добавление парковки
     * @request PUT:/v1/parkings
     */
    adminGatewayCreateParking: (body: CreateParkingRequest, params: RequestParams = {}) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/parkings`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayParkingsWithClusters
     * @summary Получение парковок с кластерами
     * @request POST:/v1/parkings/with-clusters
     */
    adminGatewayParkingsWithClusters: (
      body: ParkingsWithClustersRequest,
      params: RequestParams = {}
    ) =>
      this.request<ParkingsWithClustersResponse, GooglerpcStatus>({
        path: `/v1/parkings/with-clusters`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayParking
     * @summary Получение одной парковки
     * @request GET:/v1/parkings/{uuid}
     */
    adminGatewayParking: (uuid: string, params: RequestParams = {}) =>
      this.request<GetParkingResponse, GooglerpcStatus>({
        path: `/v1/parkings/${uuid}`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeleteParking
     * @summary Удаление парковки по uuid
     * @request DELETE:/v1/parkings/{uuid}
     */
    adminGatewayDeleteParking: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/parkings/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayEditParking
     * @summary Редактирование парковки по uuid
     * @request PATCH:/v1/parkings/{uuid}
     */
    adminGatewayEditParking: (
      uuid: string,
      body: {
        /** Должно быть только одно из значений, в зависимости от type */
        location?: ParkingLocation;
        name?: Record<string, string>;
        description?: Record<string, string>;
        contacts?: Record<string, string>;
        address?: Address;
        center?: Point;
        /** uuid зоны */
        zoneUuid?: Uuid;
        /** uuid категории */
        categoryUuid?: Uuid;
        /** @format int32 */
        spacesTotal?: number;
        /** @format int32 */
        spacesHandicapped?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/parkings/${uuid}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetPermission
     * @summary Получение конкретного разрешения
     * @request GET:/v1/permission
     */
    adminGatewayGetPermission: (
      query: AdminGatewayGetPermissionParams,
      params: RequestParams = {}
    ) =>
      this.request<PermissionFull, GooglerpcStatus>({
        path: `/v1/permission`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayPermissions
     * @summary Справочник разрешений
     * @request GET:/v1/permissions
     */
    adminGatewayPermissions: (params: RequestParams = {}) =>
      this.request<PermissionsResponse, GooglerpcStatus>({
        path: `/v1/permissions`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreatePermission
     * @summary Добавление разрешения
     * @request PUT:/v1/permissions
     */
    adminGatewayCreatePermission: (body: PermissionCreateRequest, params: RequestParams = {}) =>
      this.request<PermissionDefaultResponse, GooglerpcStatus>({
        path: `/v1/permissions`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetPermissionGrant
     * @summary Получение конкретного назначения разрешения
     * @request GET:/v1/permissions/grant
     */
    adminGatewayGetPermissionGrant: (
      query: AdminGatewayGetPermissionGrantParams,
      params: RequestParams = {}
    ) =>
      this.request<PermissionGrant, GooglerpcStatus>({
        path: `/v1/permissions/grant`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayPermissionGrants
     * @summary Поиск назначения разрешения
     * @request POST:/v1/permissions/grants
     */
    adminGatewayPermissionGrants: (body: PermissionsGrantsRequest, params: RequestParams = {}) =>
      this.request<PermissionsGrantsResponse, GooglerpcStatus>({
        path: `/v1/permissions/grants`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreatePermissionGrant
     * @summary Создание назначения разрешения
     * @request PUT:/v1/permissions/grants
     */
    adminGatewayCreatePermissionGrant: (
      body: CreatePermissionsGrantRequest,
      params: RequestParams = {}
    ) =>
      this.request<PermissionDefaultResponse, GooglerpcStatus>({
        path: `/v1/permissions/grants`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeletePermissionGrant
     * @summary Удаление назначения разрешения
     * @request DELETE:/v1/permissions/grants/{uuid}
     */
    adminGatewayDeletePermissionGrant: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/permissions/grants/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeletePermission
     * @summary Удаление разрешения
     * @request DELETE:/v1/permissions/{uuid}
     */
    adminGatewayDeletePermission: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/permissions/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayEditPermission
     * @summary Редактирование разрешения
     * @request PATCH:/v1/permissions/{uuid}
     */
    adminGatewayEditPermission: (
      uuid: string,
      body: {
        /** Название */
        name?: Record<string, string>;
        /**
         * цена
         * @format int64
         */
        price?: string;
        /** Время действия по дням недели. 1 - Понедельник, 2 - Вт. и т.д. */
        dayOfWeeks?: number[];
        /**
         * время действия С. Пример (00:00): 0
         * @format int32
         */
        start?: number;
        /**
         * время действия По. Пример (23:59): 46800
         * @format int32
         */
        end?: number;
        /** Количество лет/месяцев/дней */
        duration?: Duration;
        /** Начало действия назначения после приобретения */
        startType?: StartType;
        /** Тип тарификации */
        tarificationType?: string;
        /** номера зон */
        zones?: string[];
      },
      params: RequestParams = {}
    ) =>
      this.request<PermissionDefaultResponse, GooglerpcStatus>({
        path: `/v1/permissions/${uuid}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetRoadsideSessions
     * @summary Придорожные парковки. Получение списка сессий
     * @request GET:/v1/roadside
     */
    adminGatewayGetRoadsideSessions: (
      query: AdminGatewayGetRoadsideSessionsParams,
      params: RequestParams = {}
    ) =>
      this.request<RoadsideSessionsResponse, GooglerpcStatus>({
        path: `/v1/roadside`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetRoadsideSession
     * @summary Придорожные парковки. Получение сессии
     * @request GET:/v1/roadside/{uuid}
     */
    adminGatewayGetRoadsideSession: (uuid: string, params: RequestParams = {}) =>
      this.request<RoadsideSession, GooglerpcStatus>({
        path: `/v1/roadside/${uuid}`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayRoleList
     * @summary Получение списка ролей
     * @request GET:/v1/roles
     */
    adminGatewayRoleList: (params: RequestParams = {}) =>
      this.request<GetRoleListResponse, GooglerpcStatus>({
        path: `/v1/roles`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetSystemSettings
     * @summary Настройки системы
     * @request GET:/v1/system-settings
     */
    adminGatewayGetSystemSettings: (params: RequestParams = {}) =>
      this.request<SystemSettingsConfig, GooglerpcStatus>({
        path: `/v1/system-settings`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewaySaveSystemSettings
     * @summary Настройки системы
     * @request PATCH:/v1/system-settings
     */
    adminGatewaySaveSystemSettings: (body: SystemSettingsConfig, params: RequestParams = {}) =>
      this.request<SystemSettingsConfig, GooglerpcStatus>({
        path: `/v1/system-settings`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayTariffs
     * @summary Получение списка тарифов
     * @request GET:/v1/tariffs
     */
    adminGatewayTariffs: (params: RequestParams = {}) =>
      this.request<GetTariffResponse, GooglerpcStatus>({
        path: `/v1/tariffs`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayTariff
     * @summary Получение тарифа
     * @request GET:/v1/tariffs/{uuid}
     */
    adminGatewayTariff: (uuid: string, params: RequestParams = {}) =>
      this.request<GetSingleTariffResponse, GooglerpcStatus>({
        path: `/v1/tariffs/${uuid}`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayEditTariff
     * @summary Редактирование тарифа
     * @request PATCH:/v1/tariffs/{uuid}
     */
    adminGatewayEditTariff: (
      uuid: string,
      body: {
        name?: Record<string, string>;
        description?: Record<string, string>;
      },
      params: RequestParams = {}
    ) =>
      this.request<GetSingleTariffResponse, GooglerpcStatus>({
        path: `/v1/tariffs/${uuid}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetPushLog
     * @summary Журнал исходящих ПУШ сообщений системы
     * @request GET:/v1/transport/push/log
     */
    adminGatewayGetPushLog: (query: AdminGatewayGetPushLogParams, params: RequestParams = {}) =>
      this.request<GetPushLogResponse, GooglerpcStatus>({
        path: `/v1/transport/push/log`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetSmsLog
     * @summary Журнал исходящих смс сообщений системы
     * @request GET:/v1/transport/sms/log
     */
    adminGatewayGetSmsLog: (query: AdminGatewayGetSmsLogParams, params: RequestParams = {}) =>
      this.request<GetSmsLogResponse, GooglerpcStatus>({
        path: `/v1/transport/sms/log`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetUserActivities
     * @summary Журнал действий пользователя
     * @request GET:/v1/user/{userUuid}/activities
     */
    adminGatewayGetUserActivities: (
      { userUuid, ...query }: AdminGatewayGetUserActivitiesParams,
      params: RequestParams = {}
    ) =>
      this.request<UserActivitiesResponse, GooglerpcStatus>({
        path: `/v1/user/${userUuid}/activities`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayGetUserEvents
     * @summary История событий пользователя
     * @request GET:/v1/user/{userUuid}/events
     */
    adminGatewayGetUserEvents: (
      { userUuid, ...query }: AdminGatewayGetUserEventsParams,
      params: RequestParams = {}
    ) =>
      this.request<UserEventsResponse, GooglerpcStatus>({
        path: `/v1/user/${userUuid}/events`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayUserList
     * @summary Получение списка пользователей с фильтрами
     * @request GET:/v1/users
     */
    adminGatewayUserList: (query: AdminGatewayUserListParams, params: RequestParams = {}) =>
      this.request<GetUserListResponse, GooglerpcStatus>({
        path: `/v1/users`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayUser
     * @summary Получение детальной инфы по пользователю
     * @request GET:/v1/users/{uuid}
     */
    adminGatewayUser: (uuid: string, params: RequestParams = {}) =>
      this.request<GetUserResponse, GooglerpcStatus>({
        path: `/v1/users/${uuid}`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayZones
     * @summary Получение списка зон
     * @request GET:/v1/zones
     */
    adminGatewayZones: (query: AdminGatewayZonesParams, params: RequestParams = {}) =>
      this.request<GetZonesResponse, GooglerpcStatus>({
        path: `/v1/zones`,
        method: "GET",
        query: query,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayCreateZone
     * @summary Добавление зоны
     * @request PUT:/v1/zones
     */
    adminGatewayCreateZone: (body: CreateZoneRequest, params: RequestParams = {}) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/zones`,
        method: "PUT",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayZone
     * @summary Получение одной зоны
     * @request GET:/v1/zones/{uuid}
     */
    adminGatewayZone: (uuid: string, params: RequestParams = {}) =>
      this.request<GetZoneResponse, GooglerpcStatus>({
        path: `/v1/zones/${uuid}`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayDeleteZone
     * @summary Удаление зоны по uuid
     * @request DELETE:/v1/zones/{uuid}
     */
    adminGatewayDeleteZone: (uuid: string, params: RequestParams = {}) =>
      this.request<RefillCompanyResponse, GooglerpcStatus>({
        path: `/v1/zones/${uuid}`,
        method: "DELETE",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @name AdminGatewayEditZone
     * @summary Редактирование зоны по uuid
     * @request PATCH:/v1/zones/{uuid}
     */
    adminGatewayEditZone: (
      uuid: string,
      body: {
        /** номер зоны */
        number?: string;
        /**
         * тип зоны
         * - ZONE_TYPE_UNSPECIFIED: не установлено
         *  - ZONE_TYPE_SIMPLE: Уличная парковка (УДС)
         *  - ZONE_TYPE_WITH_PERMISSION: Парковка с разрешениями
         *  - ZONE_TYPE_PRIVATE: Приватная закрытая парковка
         *  - ZONE_TYPE_GATED: Плоскостная парковка (ППЗТ)
         *  - ZONE_TYPE_CLOSED: Коммерческая парковка
         */
        type?: ZoneType;
        /** описание зоны */
        description?: Record<string, string>;
        /**
         * город
         * - CITY_UNSPECIFIED: не установлено
         *  - CITY_SOCHI: Сочи
         */
        city?: City;
        /** Полигон покрытия зоны */
        location?: Polygon;
        /** центр полигона */
        center?: Point;
        /** включить - выключить зону */
        isActive?: boolean;
        /** цены в зависимости от типа ТС */
        prices?: VehiclePrice[];
        /** uuid тарифа */
        tariffUuid?: Uuid;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateDefaultResponse, GooglerpcStatus>({
        path: `/v1/zones/${uuid}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params
      })
  };
}
