import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "pages/Login";
import { useAuthContext } from "../context/AuthContext";
import {
  PARKINGS_BASE_ROUTE,
  PARKINGS_VIEW_ROUTE,
  PARKINGS_ADD_ROUTE,
  PARKINGS_EDIT_ROUTE,
  PARKOMATS_BASE_ROUTE,
  SCOREBOARDS_BASE_ROUTE,
  TERMINALS_BASE_ROUTE,
  ZONES_BASE_ROUTE,
  ZONES_ADD_ROUTE,
  ZONES_EDIT_ROUTE,
  ZONES_VIEW_ROUTE,
  ABONEMENTS_ACTIVE_ROUTE,
  ABONEMENTS_ADD_ROUTE,
  ABONEMENTS_EDIT_ROUTE,
  PERMISSIONS_ACTIVE_ROUTE,
  PERMISSIONS_ADD_ROUTE,
  PERMISSIONS_EDIT_ROUTE,
  BENEFIT_GRANT_BASE_ROUTE,
  BENEFIT_GRANT_ADD_ROUTE,
  ABONEMENT_GRANTS_BASE_ROUTE,
  ABONEMENT_GRANTS_ADD_ROUTE,
  PERMISSION_GRANTS_BASE_ROUTE,
  PERMISSION_GRANTS_ADD_ROUTE,
  ACCOUNT_ROLES_BASE_ROUTE,
  PARKING_SESSIONS_ROUTE,
  SYSTEM_SETTING_MAP_ROUTE,
  BENEFIT_GRANT_VIEW_ROUTE,
  SYSTEM_SETTING_MOBILE_APP_ROUTE,
  SYSTEM_SETTING_AVAILABLE_LANGUAGES_ROUTE,
  SYSTEM_SETTING_VEHICLES_ROUTE,
  SYSTEM_SETTING_CURRENCY_ROUTE
} from "./route-declarations";
import { Paths } from "./const";
import Workspace from "components/Workspace";
import Home from "pages/Home";
import { Categories, Objects } from "pages/ObjectManagment";
import { AccountsList, AccountsItem, RoleList } from "pages/Accounts";
import { Tariffs, TariffItem } from "pages/Tariffs";
import { BenefitsList, BenefitGrantList, BenefitGrantForm, BenefitGrantView } from "pages/Benefits";
import { Years, HolidaysItem } from "pages/Calendar";
import ZoneList from "components/ObjectsSidebarContent/components/Zones/List";
import ZoneForms from "components/ObjectsSidebarContent/components/Zones/ZoneForms";
import ParkingList from "components/ObjectsSidebarContent/components/Parkings/List";
import ParkingForms from "components/ObjectsSidebarContent/components/Parkings/ParkingForms";
import { useAppAbility } from "../context/AbilityContext";
import { AbonementList, AbonementGrantList, AbonementGrantItem } from "pages/Abonements";
import { PermissionList, PermissionGrantList, PermissionGrantItem } from "pages/Permissions";
import AbonementItem from "pages/Abonements/Active/Item";
import PermissionItem from "pages/Permissions/Active/Item";
import { ParkingSessionList } from "pages/RoadsideSessions";
import { useTranslation } from "react-i18next";
import { AvailableLanguages, Clusters, Currency, Mobile } from "pages/SystemSettings";
import Vehicles from "pages/SystemSettings/Vehicles";
import { CompaniesList } from "pages/LegalEntityBalance";

export const PrivateRoutes = () => {
  const { isLoggedIn } = useAuthContext();
  return isLoggedIn ? <Workspace /> : <Navigate to='/login' />;
};

const PubicRoutes = () => {
  const { isLoggedIn } = useAuthContext();
  return isLoggedIn ? <Navigate to={Paths.Root} /> : <Outlet />;
};

export default () => {
  const { t } = useTranslation();
  const { can } = useAppAbility();

  return (
    <Routes>
      <Route element={<PubicRoutes />}>
        <Route element={<Login />} path={Paths.Login} />
      </Route>
      <Route element={<PrivateRoutes />}>
        <Route element={<Home />} path='/' />

        {can("read", "Category") && (
          <Route element={<Categories />} path={`${Paths.ObjectsManagment}${Paths.Categories}`} />
        )}
        <Route element={<Objects />} path={Paths.ObjectsManagment}>
          {can("read", "Zone") && (
            <>
              <Route path={ZONES_BASE_ROUTE} element={<ZoneList />} />
              <Route path={ZONES_VIEW_ROUTE} element={<ZoneForms />} />
              <Route path={ZONES_ADD_ROUTE} element={<ZoneForms />} />
              <Route path={ZONES_EDIT_ROUTE} element={<ZoneForms />} />
            </>
          )}
          {can("read", "Zone") && (
            <>
              <Route path={PARKINGS_BASE_ROUTE} element={<ParkingList />} />
              <Route path={PARKINGS_VIEW_ROUTE} element={<ParkingForms />} />
              <Route path={PARKINGS_ADD_ROUTE} element={<ParkingForms />} />
              <Route path={PARKINGS_EDIT_ROUTE} element={<ParkingForms />} />
            </>
          )}
          <Route path={PARKINGS_BASE_ROUTE} element={<h2>{t("common.section_turned_off")}</h2>} />
          <Route path={PARKOMATS_BASE_ROUTE} element={<h2>{t("common.section_turned_off")}</h2>} />
          <Route path={TERMINALS_BASE_ROUTE} element={<h2>{t("common.section_turned_off")}</h2>} />
          <Route
            path={SCOREBOARDS_BASE_ROUTE}
            element={<h2>{t("common.section_turned_off")}</h2>}
          />
        </Route>
        {can("read", "User") && (
          <>
            <Route element={<AccountsList />} path={`${Paths.Accounts}${Paths.AccountsList}`} />
            <Route element={<AccountsItem />} path={`${Paths.Accounts}/:id`} />
            {can("read", "Role") && (
              <>
                <Route element={<RoleList />} path={ACCOUNT_ROLES_BASE_ROUTE} />
              </>
            )}
          </>
        )}
        {can("read", "Tariff") && (
          <>
            <Route element={<Tariffs />} path={`${Paths.Tariffs}`} />
            <Route element={<TariffItem />} path={`${Paths.Tariffs}/:uuid`} />
          </>
        )}
        {can("read", "Holiday") && (
          <>
            <Route element={<Years />} path={`${Paths.CalendarYears}`} />
            <Route element={<HolidaysItem />} path={`${Paths.CalendarYears}/edit/:year`} />
          </>
        )}
        {can("read", "Benefit") && (
          <>
            <Route element={<BenefitsList />} path={`${Paths.Benefits}${Paths.BenefitsActive}`} />
          </>
        )}
        {can("read", "BenefitGrant") && (
          <>
            <Route element={<BenefitGrantView />} path={`${BENEFIT_GRANT_VIEW_ROUTE}`} />
            <Route element={<BenefitGrantList />} path={`${BENEFIT_GRANT_BASE_ROUTE}`} />
          </>
        )}
        {can("create", "BenefitGrant") && (
          <>
            <Route element={<BenefitGrantForm />} path={`${BENEFIT_GRANT_ADD_ROUTE}`} />
          </>
        )}
        {can("read", "Abonement") && (
          <>
            <Route element={<AbonementList />} path={`${ABONEMENTS_ACTIVE_ROUTE}`} />
          </>
        )}
        {(can("create", "Abonement") || can("read", "Abonement")) && (
          <>
            <Route element={<AbonementItem />} path={`${ABONEMENTS_ADD_ROUTE}`} />
          </>
        )}
        {(can("update", "Abonement") || can("read", "Abonement")) && (
          <>
            <Route element={<AbonementItem />} path={`${ABONEMENTS_EDIT_ROUTE}`} />
          </>
        )}
        {can("read", "AbonementGrant") && (
          <>
            <Route element={<AbonementGrantList />} path={`${ABONEMENT_GRANTS_BASE_ROUTE}`} />
          </>
        )}
        {can("create", "AbonementGrant") && (
          <>
            <Route element={<AbonementGrantItem />} path={`${ABONEMENT_GRANTS_ADD_ROUTE}`} />
          </>
        )}
        {can("read", "Permission") && (
          <>
            <Route element={<PermissionList />} path={`${PERMISSIONS_ACTIVE_ROUTE}`} />
          </>
        )}
        {(can("create", "Permission") || can("read", "Permission")) && (
          <>
            <Route element={<PermissionItem />} path={`${PERMISSIONS_ADD_ROUTE}`} />
          </>
        )}
        {(can("update", "Permission") || can("read", "Permission")) && (
          <>
            <Route element={<PermissionItem />} path={`${PERMISSIONS_EDIT_ROUTE}`} />
          </>
        )}
        {can("read", "PermissionGrant") && (
          <>
            <Route element={<PermissionGrantList />} path={`${PERMISSION_GRANTS_BASE_ROUTE}`} />
          </>
        )}
        {can("create", "PermissionGrant") && (
          <>
            <Route element={<PermissionGrantItem />} path={`${PERMISSION_GRANTS_ADD_ROUTE}`} />
          </>
        )}
        {/* // TODO: uncomment once backend is ready */}
        {/* {can("read", "PushLog") && (
          <>
            <Route element={<PushLogList />} path={`${NOTIFICATIONS_PUSH_ROUTE}`} />
          </>
        )}
        {can("read", "SmsLog") && (
          <>
            <Route element={<SmsLogList />} path={`${MONITORING_SMS_ROUTE}`} />
          </>
        )} */}

        {can("read", "ParkingSessionsLog") && (
          <>
            <Route element={<ParkingSessionList />} path={PARKING_SESSIONS_ROUTE} />
          </>
        )}
        {can("read", "SystemSettings") && (
          <>
            <Route element={<Clusters />} path={SYSTEM_SETTING_MAP_ROUTE} />
            <Route element={<Mobile />} path={SYSTEM_SETTING_MOBILE_APP_ROUTE} />
            <Route
              element={<AvailableLanguages />}
              path={SYSTEM_SETTING_AVAILABLE_LANGUAGES_ROUTE}
            />
            <Route element={<Vehicles />} path={SYSTEM_SETTING_VEHICLES_ROUTE} />
            <Route element={<Currency />} path={SYSTEM_SETTING_CURRENCY_ROUTE} />
          </>
        )}
        {can("read", "LegalEntityBalance") && (
          <Route element={<CompaniesList />} path={Paths.LegalEntityBalance} />
        )}
        <Route element={<Navigate to={can("read", "Zone") ? ZONES_BASE_ROUTE : "/"} />} path='*' />
      </Route>
    </Routes>
  );
};
