import { Typography, Box, CircularProgress } from "@mui/material";
import { useCompaniesQuery } from "hooks/queries/legal-entity-balance";
import { useTranslation } from "react-i18next";
import ComapnyCard from "../CompanyCard";
import { useState } from "react";
import ReplenishDialog from "../ReplenishDialog";
import { useForm } from "react-hook-form";
import Pagination from "components/Pagination";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import SearchBar from "../SearchBar";
import { AdminGatewayGetCompaniesParams, Company } from "api/generated";
import { useAppAbility } from "context/AbilityContext";

interface ReplenishCompany {
  uuid: string;
  balance: number;
}

const CompaniesList = () => {
  const { t } = useTranslation();
  const { can } = useAppAbility();
  const canUpdate = can("update", "LegalEntityBalance");

  const { control, setValue, watch } = useForm<AdminGatewayGetCompaniesParams>({
    defaultValues: {
      q: "",
      limit: 20,
      offset: 0
    }
  });

  const [query, setQuery] = useState<string>("");

  const doSearch = useDebouncedCallback((value: string) => {
    setQuery(value);
    setValue("offset", 0);
  }, 300);

  const handleSearch = (value: string) => {
    setValue("q", value);
    doSearch(value);
  };

  const limit = watch("limit") || 0;
  const offset = watch("offset") || 0;

  const { data, isLoading } = useCompaniesQuery({
    q: query.length >= 3 ? query : "",
    limit,
    offset
  });
  const companies = data?.data.companies;
  const total = data?.data.total ?? 0;

  const [replenishDialogOpen, setReplenishDialogOpen] = useState<boolean>(false);
  const [replenishCompany, setReplenishCompany] = useState<ReplenishCompany | null>(null);

  const handleReplenish = (company: Company) => () => {
    if (company.uuid) {
      setReplenishCompany({
        uuid: company.uuid,
        balance: company.amount ? parseInt(company.amount) : 0
      });
      setReplenishDialogOpen(true);
    }
  };

  return (
    <Box>
      <Typography component='h2' variant='h5' padding='1rem 0'>
        {t("pages.LegalEntityBalance.companies")}
      </Typography>

      <SearchBar
        placeholder={t("pages.LegalEntityBalance.search")}
        control={control}
        onSearch={handleSearch}
      />

      {isLoading ? (
        <CircularProgress sx={{ mt: 2 }} size={26} />
      ) : (
        <>
          <Box
            sx={{
              mt: 2,
              mb: 2,
              display: "grid",
              gap: 2,
              gridTemplateColumns: {
                xs: "1fr",
                lg: "repeat(2, 1fr)",
                xl: "repeat(3, 1fr)"
              }
            }}
          >
            {companies?.map((company) => (
              <ComapnyCard
                key={company.uuid}
                name={company.name ?? ""}
                balance={company.amount ? parseInt(company.amount) : 0}
                phone={company.supervisorPhone ?? ""}
                showReplenishButton={canUpdate}
                onReplenish={canUpdate ? handleReplenish(company) : undefined}
              />
            ))}
          </Box>
          <Pagination control={control} total={total} setValue={setValue} />
        </>
      )}

      {replenishCompany && canUpdate && (
        <ReplenishDialog
          companyUuid={replenishCompany.uuid}
          companyBalance={replenishCompany.balance}
          open={replenishDialogOpen}
          onClose={() => setReplenishDialogOpen(false)}
          TransitionProps={{ onExited: () => setReplenishCompany(null) }}
        />
      )}
    </Box>
  );
};

export default CompaniesList;
